import React from 'react';

import Row from '../Container/row';
import Col from '../Container/column';

import './style.scss';

import loadComponents from '../Loadable';

import { CtaInfoIcon } from '../Icons/icons';
import { LightGrey, MainColour } from '../../utils/variables';

const H3 = loadComponents('h3');
const Button = loadComponents('button');

export default function Cta(props) {
  const { data } = props;
  return (
    <div
      className="ctaOuter pageCta"
      style={{ backgroundColor: LightGrey, padding: `30px 45px` }}
    >
      <Row align="center" justify="space-between" rowGap="30px">
        <Col
          className="ctaIcon"
          xl={{ width: `90px` }}
          style={{
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
          }}
        >
          <CtaInfoIcon size="60px" />
        </Col>
        <Col className="ctaContent" xl={{ grow: true }}>
          <H3 margin="0px" colour={MainColour}>
            {data.title}
          </H3>
          <p>{data.content}</p>
        </Col>
        <Col
          className="button"
          xl={{ width: 'auto' }}
          style={{ display: `flex`, alignItems: `center` }}
        >
          <Button to={data.buttonLink} size="large" primary>
            {data.buttonText}
          </Button>
        </Col>
      </Row>
    </div>
  );
}
